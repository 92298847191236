// src/Roadmap.js
import React from 'react';
import '../css/Features.css'; // Import the stylesheet
import Navbar from '../Components/Header';
import { Footer } from '../Components/Footer';

const Features = () => {
    
  return (
   
   
   <div className='containing'>

    <Navbar></Navbar>
    
    <div className="roadmap">
      <h1>Features Road Map</h1>
      <ul>
        <li>Dynamic discovery of AWS.</li>
        <li>Build dynamic map for AWS v1.0</li>
        <li>Detailed Billing information.</li>
        <li>Build logging server for infrastructure systems (Dec 2024).</li>
        <li>Dynamic discovery of Azure, GCP (Aug 2025)</li>
        <li>Dynamic discovery of Vmware and Hyper-V (Jan 2026)</li>
      </ul>
    </div>
    </div>
   
  );
};

export default Features;
