import React from 'react';
import "../css/AwsDynamicDiscovery.css"
import Navbar from '../Components/Header';
const AwsDynamicDiscovery = () => {
  return (
    <>
    <Navbar></Navbar>
    <div className="aws-discovery">
      <h1>AWS Dynamic Discovery</h1>
      <ul>
        <li>Dynamic map will build connectivity for discovered items.</li>
        <li>
          VPC
          <ul>
            <li>Availability zone.</li>
            <li>Route tables.</li>
            <li>VPN Gateway.</li>
            <li>VPN connections.</li>
            <li>Internet GW.</li>
            <li>NAT Gateway.</li>
            <li>Transit GW.</li>
            <li>Transit GW attachment.</li>
          </ul>
        </li>
        <li>
          EC2
          <ul>
            <li>Network connections.</li>
            <li>Storage.</li>
          </ul>
        </li>
        <li>Load Balancers</li>
        <li>List continues.....</li>
      </ul>
    </div>
    </>
  );
}

export default AwsDynamicDiscovery;
