// src/components/Requirements.js
import React from 'react';
import '../css/Requirement.css'; // Import the CSS file
import Navbar from '../Components/Header';
import FeaturePricing from './Pricing';

const Requirements = () => {
  return (
    <>
    <Navbar></Navbar>
        
    <div className="requirements-container">
      <h1 className="requirements-header">Requirements</h1>
      <ul className="requirements-list">
        <li>
          <strong>AWS Read Only Access key and Secret Key.</strong>
        </li>
        <li>
          <strong>S3 bucket access for the following:</strong>
          <ul className="requirements-sublist">
            <li>VPC traffic logs of CloudWatch.</li>
            <li>CloudTrail.</li>
            <li>Billing</li>
          </ul>
        </li>
      </ul>
    </div>
    <FeaturePricing></FeaturePricing>
    
    
    </>
  );
};

export default Requirements;
