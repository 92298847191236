import React from 'react';
import '../css/LoggingSystem.css';  // Import the CSS file
import Navbar from '../Components/Header';

const LoggingSystem = () => {
  return (
    <>
    <Navbar></Navbar>
    <div className="logging-container">
      <h1 className="logging-header">Logging System</h1>
      <ul className="logging-list">
        <li>Purpose: Build ..... </li>
        <li>Gather information from the following:</li>
        <ul className="logging-sublist">
          <li>Network components</li>
          <li>AWS traffic</li>
          <li>Actions on AWS (Create, edit, and delete)</li>
        </ul>
      </ul>
    </div>
    </>
  );
};

export default LoggingSystem;
