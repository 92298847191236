import React from 'react'
import '../css/Agenda.css';
import Navbar from '../Components/Header';
import { Link } from 'react-router-dom';

const Agenda = () => {
  return (
    <div className='containing'>
    <Navbar></Navbar>
    <div>
    <div className="agenda-container">
      <h2 className="agenda-title">Agenda</h2>
      <ul className="agenda-list">
        <li ><Link to={'intro'}>Company and software introduction.</Link></li>
        <li><Link to={'features'}>Features road map</Link></li>
        <li><Link to={'AwsDynamicDiscovery'}>Features details</Link></li>
        <li><Link to={'SystemComponent'}>System components</Link></li>
        <li><Link to={'LoggingSystem'}>Logging System</Link></li>
        <li><Link to={'Requirement'}>Requirements & Feature pricing</Link></li>

      </ul>
    </div>
    </div>
    </div>
  )
}

export default Agenda




   