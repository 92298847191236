import React from 'react';
import '../css/SystemComponent.css';  // Import the CSS file
import Navbar from '../Components/Header';

const SystemComponents = () => {
  return (

    <>
    <Navbar></Navbar>
    <div className="system-container">
      <h1 className="system-header">System Components</h1>
      <ul className="system-list">
        <li>OPV Front end IIS Web Server.</li>
        <ul className="system-sublist">
          <li>On-Prem</li>
        </ul>
        <li>OPV Back End IIS Web Server</li>
        <ul className="system-sublist">
          <li>On-Prem</li>
        </ul>
        <li>Cloud</li>
        <li>PostgreSQL database</li>
        <ul className="system-sublist">
          <li>On-Prem</li>
          <li>Cloud</li>
        </ul>
      </ul>
    </div>
</>    

  );
};

export default SystemComponents;
