import { createBrowserRouter } from "react-router-dom";
// import Navbar from "../Components/Header";
import Introduction from "../pages/Introduction";
import Agenda from "../pages/Agenda";
import Features from "../pages/Features";
import AwsDynamicDiscovery from "../pages/AwsDynamicDiscovery";
import SystemComponent from "../pages/SystemComponent";
import LoggingSystem from "../pages/LoggingSystem";
import Requirements from "../pages/Requirement";
import FeaturePricing from "../pages/Pricing";
// import App from "../.App";




export const router = createBrowserRouter([
  {
    path: "/",
    element: <Agenda/>,
  },
  {
    path: "/intro",
    element: <Introduction/>,
  },
  {
    path: "/features",
    element: <Features/>,
  },
  {
    path: "/AwsDynamicDiscovery",
    element: <AwsDynamicDiscovery/>,
  },
  {
    path: "/SystemComponent",
    element: <SystemComponent/>,
  },
  
  {
    path: "/LoggingSystem",
    element: <LoggingSystem/>,
  },
  
  {
    path: "/Requirement",
    element: <Requirements/>,
  },

  {
    path: "/Pricing",
    element: <FeaturePricing/>,
  },
  

]);
