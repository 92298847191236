import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Navbar.css';
// import { FaSearch } from 'react-icons/fa';
import HomeLogo from "../assets/images/HomeLogo.png"
// import Introduction from '../pages/Introduction';

const Navbar = () => {
  const [isProductsDropdownOpen, setProductsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleProductsDropdown = () => {
    setProductsDropdownOpen(!isProductsDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* Logo */}
        <Link to="/" className="navbar-logo">
          {/*<strong>ManageEngine</strong> <span>Site24x7</span> */}
          <img className="HomeLogo" src={HomeLogo} alt="Home Logo" />
        </Link>

        {/* Mobile Menu Icon */}
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {/* <GiHamburgerMenu size={24} /> */}
        </div>

        {/* Navigation Links */}
        <ul className={`navbar-links ${isMobileMenuOpen ? 'mobile-active' : ''}`}>
          {/* Dropdown List Commented Out */}
          {/*
          <li className="navbar-link">
            <button
              onClick={toggleProductsDropdown}
              className={`navbar-dropdown-btn ${isProductsDropdownOpen ? 'open' : ''}`}
            >
              Products
            </button>
            {isProductsDropdownOpen && (
              <ul className="dropdown-menu">
                <li><Link to="#product1">Product 1</Link></li>
                <li><Link to="#product2">Product 2</Link></li>
                <li><Link to="#product3">Product 3</Link></li>
              </ul>
            )}
          </li>
          */}
          <li className="navbar-link"><Link to={'/'}>Agenda</Link></li>
          <li className="navbar-link"><Link to={'/intro'}>software Introduction</Link></li>
          <li className="navbar-link"><Link to={'/features'}>Features</Link></li>
          <li className="navbar-link"><Link to={"/AwsDynamicDiscovery"}>Features details</Link></li>
          <li className="navbar-link"><Link to={"/SystemComponent"}>System components</Link></li>
          <li className="navbar-link"><Link to={"/LoggingSystem"}>Logging System</Link></li>
          <li className="navbar-link"><Link to={"/Requirement"}>Requirements & Feature pricing</Link></li>
        </ul>

        {/* Search and Buttons */}
        <div className="navbar-actions">
          {/* <FaSearch className="navbar-search-icon" /> */}
          <Link to="#login" className="navbar-login">Login</Link>
          <Link to="#signup" className="navbar-signup">SIGN UP</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
