import React from 'react';
import '../css/Introduction.css'; // Make sure to create a CSS file for styling
import Navbar from '../Components/Header';



const Introduction = () => {
 
 
  return (

    <>
  <Navbar></Navbar>

    <div className="introduction-container">
      <div className="intro-card">
        <h1>Operation Views</h1>
        <p className="intro-date">Established in January 2024</p>
        <p className="intro-description">
          Operation Views targets the dynamic discovery of different systems, including:
        </p>
        <ul className="systems-list">
          <li>AWS Cloud</li>
          <li>Azure Cloud</li>
          <li>GCP</li>
          <li>VMware</li>
          <li>Hyper-V</li>
          <li>On-prem devices</li>
        </ul>
      </div>
    </div>
    </>
  );
};

export default Introduction;
