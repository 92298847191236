// src/components/FeaturePricing.js
import React from 'react';
import '../css/Pricing.css'; // Import the CSS file
import Navbar from '../Components/Header';

const FeaturePricing = () => {
  return (
    <>
    {/* <Navbar></Navbar> */}
    <div className="feature-pricing-container">
      <h1 className="feature-pricing-header">Feature Pricing</h1>
      <table className="feature-pricing-table">
        <thead>
          <tr>
            <th>Feature</th>
            <th>Status</th>
            <th>Yearly USD (paid quarterly)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>AWS Discovery</td>
            <td>Available</td>
            <td>$600</td>
          </tr>
          <tr>
            <td>AWS Map V1.0</td>
            <td>Available</td>
            <td>$600</td>
          </tr>
          <tr>
            <td>AWS Map V1.1</td>
            <td></td>
            <td>$800</td>
          </tr>
        </tbody>
      </table>
    </div>
    </>
  );
};

export default FeaturePricing;
